"use strict";

(function ($) {
  var Sage = {
    //**************************************************************************************************
    // Kör script på startsidan.
    //**************************************************************************************************
    home: {
      init: function init() {},
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    //**************************************************************************************************
    // Kör script på [SIDA].
    //**************************************************************************************************
    custom_subpage: {
      init: function init() {
        $(document).ready(function () {});
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    //**************************************************************************************************
    // Kör script på kontaktsidan.
    //**************************************************************************************************
    "kontakta-oss": {
      init: function init() {
        $(document).ready(function () {
          // Validering
          //**********************************************************************************************
          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#").validate({
                  rules: {
                    name: {
                      required: true
                    },
                    phone: {
                      required: true
                    },
                    email: {
                      required: true,
                      email: true
                    },
                    message: {
                      required: true
                    }
                  },
                  messages: {
                    name: "",
                    phone: "",
                    email: "",
                    message: ""
                  },
                  submitHandler: function submitHandler(form) {
                    form.submit();
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document);
        }); //Slut på document.ready
      }
    },
    //**************************************************************************************************
    // Kör script på ALLA sidor.
    //**************************************************************************************************
    common: {
      init: function init() {
        $("#filter button").each(function () {
          $(this).on("click", function () {
            var filtertag = $(this).attr("class");
            $(".post").show();
            $(".post:not(." + filtertag + ")").hide();
          });
        });
        $(".hs_toggle").click(function () {
          $(".hs_holder").toggleClass("open", 1000);
          $(".header-search").toggleClass("bg", 1000);
        });
        var count = 1;
        $(".add_row").click(function () {
          $(".anmalanGrupp").append('<div class="rowCell anmalanPerson" data-part="' + count + '"><div class="row"><input type="text" class="inputbox input_deltagare" name="regFormGruppPerson[' + count + '][0]" placeholder="Förnamn *" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row"><input type="text" class="inputbox input_deltagare" name="regFormGruppPerson[' + count + '][1]" placeholder="Efternamn *" ><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row"><input type="tel" class="inputbox input_deltagare" name="regFormGruppPerson[' + count + '][2]" placeholder="Telefon *"><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="row"><input type="email" class="inputbox input_deltagare" name="regFormGruppPerson[' + count + '][3]" placeholder="Email *"><i class="fa fa-check"></i><i class="fa fa-times"></i></div><div class="remove" data-remove="' + count + '"><i class="fa fa-times" aria-hidden="true"></i></div></div>');
          setupDynamicValidation();
          count++;
        });
        $(document).on("click", ".remove", function () {
          removepart = $(this).attr("data-remove"); //console.log(removepart);

          $("[data-part='" + removepart + "']").remove();
          setupDynamicValidation();
        });
        $(document).ready(function () {
          // Toggle Login Form
          $(".toggle_login_form").click(function () {
            $("#sign_in_overlay").toggleClass("vissible");
          }); // Validering
          //**********************************************************************************************

          function setupDynamicValidation() {
            var rules = {
              regForm_fornamn: {
                required: true
              },
              regForm_efternamn: {
                required: true
              },
              regForm_foretag: {
                required: true
              },
              regForm_adress: {
                required: true
              },
              regForm_stad: {
                required: true
              },
              regForm_land: {
                required: true
              },
              regForm_postnummer: {
                required: true
              },
              regForm_telefon: {
                required: true
              },
              regForm_email: {
                required: true,
                email: true
              },
              regForm_message: {
                required: true
              }
            };
            var messages = {
              regForm_fornamn: "Fyll i ditt förnamn.",
              regForm_efternamn: "Fyll i ditt efternamn.",
              regForm_foretag: "Fyll i företagsnamn.",
              regForm_adress: "Fyll i din adress.",
              regForm_stad: "Fyll i din stad.",
              regForm_land: "Fyll i ditt land.",
              regForm_postnummer: "Fyll i ditt postnummer.",
              regForm_telefon: "Fyll i ditt telefonnummer.",
              regForm_email: "Fyll i din e-postadress.",
              regForm_message: "Fyll i ditt meddelande."
            };
            var fields = $(".input_deltagare");
            fields.each(function () {
              var name = $(this).prop("name");
              rules[name] = {
                required: true
              };
              messages[name] = "Vänligen fyll i fältet.";
            });

            if (window.dynamicValidator) {
              window.dynamicValidator.destroy();
              $(".error").remove();
            }

            window.dynamicValidator = $("#regForm").validate({
              rules: rules,
              messages: messages,
              submitHandler: function submitHandler(form) {
                var grecaptchaResponse = document.getElementById('g-recaptcha-response-reg-form');
                grecaptcha.ready(function () {
                  grecaptcha.execute('6Lej8IUaAAAAAN-tx9Z_6RmrVWNt2x0o4oJrqwYP', {
                    action: 'ameab_recaptcha_action'
                  }).then(function (token) {
                    if (grecaptchaResponse !== null) {
                      grecaptchaResponse.value = token;
                      form.submit();
                    }
                  });
                });
              }
            });
          }

          if ($("#regForm").length > 0) {
            setupDynamicValidation();
          }

          window.setupDynamicValidation = setupDynamicValidation; // Validering deltagare
          //**********************************************************************************************
          // const fields = $('.input_deltagare');
          // const allFields = [];
          // let rules = {};
          // fields.each(function() {
          //     allFields.push($(this).prop('name'));
          // });
          // allFields = allFields.join(' ');
          // let groupsObj = {
          //     number: allFields
          // };
          // fields.each(function() {
          //     rules[$(this).prop('name')] = {
          //       required: true,
          //     };
          // });
          // //Setup messages
          // let messages = {};
          // let message = 'Vänligen fyll i fälten';
          // fields.each(function() {
          //   let self = $(this);
          //   let key = self.prop('name');
          //   // message = self.data('v-message');
          //   messages[key] = message;
          // });
          // $('#regForm').validate({
          //   groups: groupsObj,
          //   rules: rules,
          //   messages: messages,
          //   debug: true
          // });
          // Validering
          //**********************************************************************************************

          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#footerFormular").validate({
                  rules: {
                    sefNamn: {
                      required: true
                    },
                    sefEmail: {
                      required: true,
                      email: true
                    },
                    sefMessage: {
                      required: true
                    }
                  },
                  messages: {
                    sefNamn: "Fyll i ditt namn.",
                    sefEmail: "Fyll i din e-post.",
                    sefMessage: "Fyll i din fråga."
                  },
                  submitHandler: function submitHandler(form) {
                    var grecaptchaResponse = document.getElementById('g-recaptcha-response-footer-formular');
                    grecaptcha.ready(function () {
                      grecaptcha.execute('6Lej8IUaAAAAAN-tx9Z_6RmrVWNt2x0o4oJrqwYP', {
                        action: 'ameab_recaptcha_action'
                      }).then(function (token) {
                        if (grecaptchaResponse !== null) {
                          grecaptchaResponse.value = token;
                          form.submit();
                        }
                      });
                    });
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); // Validering
          //**********************************************************************************************


          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#infoBox").validate({
                  rules: {
                    info_foretagsnamn: {
                      required: true
                    },
                    info_namn: {
                      required: true
                    },
                    info_email: {
                      required: true,
                      email: true
                    },
                    info_message: {
                      required: true
                    }
                  },
                  messages: {
                    info_namn: "Fyll i ditt namn.",
                    info_foretagsnamn: "Fyll i ditt företagsnamn.",
                    info_email: "Fyll i din e-post.",
                    info_message: "Fyll i ett meddelande."
                  },
                  submitHandler: function submitHandler(form) {
                    grecaptcha.execute();
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); // Validering
          //**********************************************************************************************


          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#PDFDownloadForm").validate({
                  rules: {
                    downloadName: {
                      required: true
                    },
                    downloadCompany: {
                      required: true
                    },
                    downloadEmail: {
                      required: true,
                      email: true
                    }
                  },
                  messages: {
                    downloadName: "Fyll i ditt namn.",
                    downloadCompany: "Fyll i ditt företagsnamn.",
                    downloadEmail: "Fyll i din e-post."
                  },
                  submitHandler: function submitHandler(form) {
                    recaptcha.execute();
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); // Validering
          //**********************************************************************************************


          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#kontaktform").validate({
                  rules: {
                    kontakt_namn: {
                      required: true
                    },
                    kontakt_foretag: {
                      required: true
                    },
                    kontakt_telefon: {
                      required: true
                    },
                    kontakt_email: {
                      required: true,
                      email: true
                    },
                    kontakt_message: {
                      required: true
                    }
                  },
                  messages: {
                    kontakt_namn: "Fyll i ditt namn.",
                    kontakt_foretag: "Fyll i ditt företagsnamn.",
                    kontakt_telefon: "Fyll i ditt telefonnummer.",
                    kontakt_email: "Fyll i din e-post.",
                    kontakt_message: "Fyll i ett meddelande."
                  },
                  submitHandler: function submitHandler(form) {
                    var grecaptchaResponse = document.getElementById('g-recaptcha-response-kontaktform');
                    grecaptcha.ready(function () {
                      grecaptcha.execute('6Lej8IUaAAAAAN-tx9Z_6RmrVWNt2x0o4oJrqwYP', {
                        action: 'ameab_recaptcha_action'
                      }).then(function (token) {
                        if (grecaptchaResponse !== null) {
                          grecaptchaResponse.value = token;
                          form.submit();
                        }
                      });
                    });
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document); // Validering
          //**********************************************************************************************


          (function ($, W, D) {
            var JQUERY4U = {};
            JQUERY4U.UTIL = {
              setupFormValidation: function setupFormValidation() {
                //Validation rules
                $("#bestallInformation").validate({
                  rules: {
                    bestallInfoNamn: {
                      required: true
                    },
                    bestallInfoTelefon: {
                      required: true
                    },
                    bestallInfoEmail: {
                      required: true,
                      email: true
                    },
                    bestallInfoMessage: {
                      required: true
                    }
                  },
                  messages: {
                    bestallInfoNamn: "Fyll i ditt namn.",
                    bestallInfoTelefon: "Fyll i ditt telefonnummer.",
                    bestallInfoEmail: "Fyll i din e-post.",
                    bestallInfoMessage: "Fyll i ett meddelande."
                  },
                  submitHandler: function submitHandler(form) {
                    var grecaptchaResponse = document.getElementById('g-recaptcha-response-bestallInformation');
                    grecaptcha.ready(function () {
                      grecaptcha.execute('6Lej8IUaAAAAAN-tx9Z_6RmrVWNt2x0o4oJrqwYP', {
                        action: 'ameab_recaptcha_action'
                      }).then(function (token) {
                        if (grecaptchaResponse !== null) {
                          grecaptchaResponse.value = token;
                          form.submit();
                        }
                      });
                    });
                  }
                });
              }
            };
            $(D).ready(function ($) {
              JQUERY4U.UTIL.setupFormValidation();
            });
          })(jQuery, window, document);

          function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();
            return elemBottom <= docViewBottom && elemTop >= docViewTop;
          }

          window.setTimeout(function () {
            $(".footerSectionHome").addClass("small");
          }, 3000);
          window.setTimeout(function () {
            $(".footerSectionHome").addClass("right");
          }, 4000);
          $(window).scroll(function () {
            if (isScrolledIntoView("#footer") === true) {
              $(".footerSection").addClass("animated", 100);
              $(".footerSection").addClass("away", 100);
            }

            if (isScrolledIntoView("#footer") === false) {
              $(".footerSection").removeClass("animated", 2000);
              $(".footerSection").removeClass("away", 2000);
            }

            if ($("#footer .wrapper").hasClass("hidden") === false) {
              if (isScrolledIntoView("#footer .inner-wrapper")) {
                $(".footerSectionHome").addClass("animated", 100);
                $(".footerSectionHome").addClass("away", 100);
              } else {
                $(".footerSectionHome").removeClass("animated", 2000);
                $(".footerSectionHome").removeClass("away", 2000);
              }
            }
          }); //$(window).scroll(function(){
          //    if ($(this).scrollTop() > 100) {
          //            $('.footerSectionHome').addClass('small');
          //        } else {
          //            $('.footerSectionHome').removeClass('small');
          //        }
          //    });

          $(".scrollToBottom").click(function () {
            $("html, body").animate({
              scrollTop: $(document).height()
            }, 1000);
            $("#footer > .wrapper").removeClass("hidden");
          }); // BANNER - START
          //****************************************

          var totalItems = $(".item").length;

          if (totalItems == 1) {
            // Set loop option variable to false
            var isLooped = false;
          } else {
            // Set loop option variable to true
            var isLooped = true;
          }

          var n = $("li.item").length;

          if (n > 1) {
            var trueFalse = true;
          } else {
            var trueFalse = false;
          }

          $("#owl-start").owlCarousel({
            mouseDrag: trueFalse,
            touchDrag: trueFalse,
            autoplay: 5000,
            autoplayTimeout: 5000,
            paginationSpeed: 5000,
            smartSpeed: 1000,
            loop: isLooped,
            nav: true,
            items: 1,
            itemsDesktop: false,
            itemsDesktopSmall: false,
            itemsTablet: false,
            itemsMobile: false
          }); // Visa dölj menyn (SlideToggle)
          //**********************************************************************************************

          $("#toggle_navigation").click(function () {
            $(this).toggleClass("open");
            $("#main_navigation").toggleClass("open", 500);
          });
          $("#flexibelSidebar ul li.current-menu-item .sub-menu").click(function () {
            if ($(this).html() === '<i class="fa fa-plus-square-o" aria-hidden="true"></i>') {
              $(this).html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
              $(this).parent("li").children(".sub-menu").addClass("openSesame");
            } else {
              $(this).html('<i class="fa fa-plus-square-o" aria-hidden="true"></i></i>');
              $(this).parent("li").children(".sub-menu").removeClass("openSesame");
            }
          });
          var windowSize = $(window).width();

          if (windowSize < 967) {
            $('<span class="growMob"><i class="fa fa-plus-square-o" aria-hidden="true"></i></span>').insertAfter("#main_navigation ul li.menu-item-has-children > a");
            $(".growMob").click(function () {
              if ($(this).html() === '<i class="fa fa-plus-square-o" aria-hidden="true"></i>') {
                $(this).html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
                $(this).parent("li").children(".sub-menu").addClass("openSesame");
              } else {
                $(this).html('<i class="fa fa-plus-square-o" aria-hidden="true"></i></i>');
                $(this).parent("li").children(".sub-menu").removeClass("openSesame");
              }
            });
          } // Lägg till span.grow på undermenyer för lättare toggle.
          //**********************************************************************************************


          $('<span class="grow"><i class="fa fa-plus-square-o" aria-hidden="true"></i></span>').insertAfter("#main_navigation ul li.menu-item-has-children ul li.menu-item-has-children > a");
          $(".grow").click(function () {
            if ($(this).html() === '<i class="fa fa-plus-square-o" aria-hidden="true"></i>') {
              $(this).html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
              $(this).parent("li").children(".sub-menu").slideToggle(400);
            } else {
              $(this).html('<i class="fa fa-plus-square-o" aria-hidden="true"></i></i>');
              $(this).parent("li").children(".sub-menu").slideToggle(400);
            }
          });
          $('<span class="growSub"><i class="fa fa-plus-square-o" aria-hidden="true"></i></span>').insertAfter(".sub_nav ul li.menu-item-has-children > a");
          $(".growSub").click(function () {
            if ($(this).html() === '<i class="fa fa-plus-square-o" aria-hidden="true"></i>') {
              $(this).html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
              $(this).parent("li").children(".sub-menu").slideToggle(400);
            } else {
              $(this).html('<i class="fa fa-plus-square-o" aria-hidden="true"></i></i>');
              $(this).parent("li").children(".sub-menu").slideToggle(400);
            }
          });
          $('<span class="growFile"><i class="fa fa-plus-square-o" aria-hidden="true"></i></span>').insertAfter(".downloadCategory .downloadSubCategory .downloadSubCategoryTitle");
          $(".growFile").click(function () {
            if ($(this).html() === '<i class="fa fa-plus-square-o" aria-hidden="true"></i>') {
              $(this).html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
              $(this).parent().children(".downloadFile").slideToggle(400);
            } else {
              $(this).html('<i class="fa fa-plus-square-o" aria-hidden="true"></i></i>');
              $(this).parent().children(".downloadFile").slideToggle(400);
            }
          });

          if ($("#flexibelSidebar ul li.current-menu-item .sub-menu").css("display") == "block") {
            $("#flexibelSidebar ul li.current-menu-item .growSub").html('<i class="fa fa-minus-square-o" aria-hidden="true"></i>');
          } // Lägg på classer på meny för att docka den vid scroll.
          //**********************************************************************************************
          // $(window).scroll(function(){
          //     if ($(this).scrollTop() > 100) {
          //         $('#header').addClass('fixed');
          //     } else {
          //         $('#header').removeClass('fixed');
          //     }
          //     if ($(this).scrollTop() > 300) {
          //         $('#header').addClass('vissible');
          //     } else {
          //         $('#header').removeClass('vissible');
          //     }
          // });
          //**********************************************************************************************
          // Lägger till Valid eller Error på inputs, textarea och select vid validering
          //**********************************************************************************************


          $(".row input.requiredField, .row select.requiredField, .row textarea.requiredField").blur(function () {
            var $this = $(this);
            var parent = $this.parent();

            if ($(this).val().trim().length != 0) {
              $this.addClass("valid");
              $this.removeClass("error");
            } else {
              $this.addClass("error");
              $this.removeClass("valid");
            }
          }); //**********************************************************************************************
          // Placera sidfoten i botten på alla sidor om utrymme uppåt finns.
          //**********************************************************************************************

          var docHeight = $(window).height();
          var footerHeight = $("#footer").height();
          var footerTop = $("#footer").position().top + footerHeight;

          if (footerTop < docHeight) {
            $("#footer").css("margin-top", docHeight - footerTop + "px");
          } //**********************************************************************************************
          // Ta bort data-src när bilden är laddad.
          //**********************************************************************************************


          [].forEach.call(document.querySelectorAll("img[data-src]"), function (img) {
            img.setAttribute("src", img.getAttribute("data-src"));

            img.onload = function () {
              img.removeAttribute("data-src");
            };
          }); //**********************************************************************************************
          // Spåra Utklick (GA)
          //**********************************************************************************************

          function _gaLt(event) {
            var el = event.srcElement || event.target;

            while (el && (typeof el.tagName == "undefined" || el.tagName.toLowerCase() != "a" || !el.href)) {
              el = el.parentNode;
            }

            if (el && el.href) {
              var link = el.href;

              if (link.indexOf(location.host) == -1 && !link.match(/^javascript\:/i)) {
                var hitBack = function hitBack(link, target) {
                  target ? window.open(link, target) : window.location.href = link;
                };

                var target = el.target && !el.target.match(/^_(self|parent|top)$/i) ? el.target : false;
                ga("send", "event", "Outgoing Links", link, document.location.pathname + document.location.search, {
                  hitCallback: hitBack(link, target)
                });
                event.preventDefault ? event.preventDefault() : event.returnValue = !1;
              }
            }
          }

          var w = window;
          w.addEventListener ? w.addEventListener("load", function () {
            document.body.addEventListener("click", _gaLt, !1);
          }, !1) : w.attachEvent && w.attachEvent("onload", function () {
            document.body.attachEvent("onclick", _gaLt);
          });
        }); //End of $(document).ready(function()
      },
      finalize: function finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire("common"); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      }); // Fire common finalize JS

      UTIL.fire("common", "finalize");
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.